import React from 'react'
import {BrowserRouter as Router} from "react-router-dom";
import {PRODUCTION} from "./config"
import { createBrowserHistory } from "history";

import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    ApolloProvider
}from '@apollo/client'

const history = createBrowserHistory();
const httpLink = createHttpLink({
    uri:PRODUCTION?"http://plagiat.insa-toulouse.fr:443/":"http://localhost:5500/"
})
const client = new ApolloClient({
    link:httpLink,
    cache:new InMemoryCache()
})
const AppoloProvider = ({component:Component,...res})=>{
    return(
        <Router history={history}>
            <ApolloProvider client={client}>
                <Component/>
            </ApolloProvider>
        </Router>    
    )
}
export default AppoloProvider