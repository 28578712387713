import React,{Suspense} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


import Appolo from "./ApolloProvider"
const App = React.lazy(()=> import('./App'))
const Admin = React.lazy(()=>import('./Admin'))
ReactDOM.render(

  <BrowserRouter>
    <Switch>

        <Route path="/admin"
            render={
                (props)=>(
                <Suspense fallback={<div>Loading..</div>}>
                    <Appolo component={Admin}/>
                </Suspense>
            
                )
            }
        />
        <Route path="/app"
            render={
                (props)=>(
                <Suspense fallback={<div>Loading..</div>}>
                    <Appolo component={App}/>
                </Suspense>
            
                )
            }
        />
        <Redirect to="/app" />
    </Switch>
    
  </BrowserRouter>,
  document.getElementById("root")
);
